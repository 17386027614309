@import '~styles/fontSizes';
@import '~styles/colors';

$border-radius: 8px;

.surveyGroupTeaser {
  background-color: $component-background;
  display: flex;
  height: 146px;
  overflow: hidden;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid $light-gray;
  }

  .iconContainer {
    align-items: center;
    background-color: #e8e9ff;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 182px;
  }

  .surveyGroupContent {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: 20px 24px 24px 28px;

    > .requestTitle {
      margin-bottom: 8px;
    }

    > .results {
      align-self: flex-end;
    }
  }

  .statusDateContainer {
    display: flex;
    flex-grow: 2;
    font-size: $font-small;

    > .updatedAt {
      color: $info-color;
      font-weight: 400;
      margin-right: 12px;
    }
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.leadingClassificationTopic {
  align-items: center;
  display: flex;
  gap: 8px;
}

.zcdIconButtonContainerWidth {
  width: 32px;
}

.leadingClassificationSubTopics {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-inline-start: 24px;

  > * {
    padding-inline-start: 24px;
    // The width of the classification sub topics is
    // 100% subtracted by the width of the margin + padding on the left.
    width: calc(100% - 48px);
  }
}

.topicSection {
  &:not(:last-child) {
    border-bottom: 1px solid zcd-colors.$zcd-gray-40;
  }
}

.topic {
  @include zcd-typography.t-heading-5;
}

.responses {
  @include zcd-typography.t-body;

  color: zcd-colors.$zcd-gray-80;
  margin-block-end: 8px;
}

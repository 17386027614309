@import '~styles/colors';
@import '~styles/fontSizes';

$label-min-width: 38px;
$left-side-width: $label-min-width + 16px;

.questionSummary {
  border-bottom: 1px solid var(--light-gray);
  display: flex;
  flex-direction: row;

  &:last-child {
    border-bottom: 0;
  }

  > .iconSide {
    // left side which has only the label (badge) with the icon and index number
    width: $left-side-width;
  }

  > .contentSide {
    // right side which holds the texts and bars
    width: calc(100% - #{$left-side-width});

    > h5 {
      color: var(--info-color);
      font-weight: 400;
      margin-bottom: 16px;
    }

    > h3,
    .scaleDescription {
      margin-bottom: 4px;
    }

    > .footNote {
      color: $info-color;
      font-size: $font-small;
      margin-top: 28px;
    }
  }
}

.positionLabel {
  align-items: center;
  background-color: $lavender-color;
  border-radius: 4px;
  color: $component-background;
  display: inline-flex;
  flex-direction: row;
  font-weight: 600;
  justify-content: center;
  min-width: $label-min-width;
  padding: 1px 4px;

  > svg {
    margin-right: 8px;
  }
}

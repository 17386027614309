@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.aspectWidget {
  .title {
    @include zcd-typography.t-heading-2;
  }

  .widgetsGrid {
    background: #ececec;
    border-radius: 0 0 8px 8px;
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    padding: 24px;

    @media only screen and (max-width: media.$screen-xlarge-width) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

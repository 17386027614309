/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
@use '../../styles/colors';
@use '../../styles/fontSizes';

.breakdownWidget {
  background-color: #fff;
  width: 100%;
  .header {
    font-size: fontSizes.$font-large;
    font-weight: 600;
    height: unset;
    padding: 20px 24px;
    a {
      color: #3463fe;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
    }
  }
  .content {
    padding: 0 24px;
    &.scrollable {
      //this is the height of three rows + 2 dividers
      height: 245px;
      overflow-y: scroll;
    }
    &.loading {
      display: flex;
      justify-content: center;
      padding: 24px;
    }
    ol {
      margin: unset;
      padding: unset;
    }
    .errorText {
      color: #d53c46;
    }
  }
  .footer {
    align-items: center;
    border-top: unset;
    display: flex;
    font-size: 14px;
    button {
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      .arrowContainer {
        display: inline-block;
        height: 21px;
        width: 21px;

        .arrow {
          $border-preset: 1px solid colors.$font-color;

          display: inline-block;
          height: 8px;
          margin-right: 8px;
          transform: rotate(45deg);
          width: 8px;
          &.down {
            border-bottom: $border-preset;
            border-right: $border-preset;
            margin-bottom: 2px;
          }
          &.up {
            border-left: $border-preset;
            border-top: $border-preset;
            margin-bottom: -2px;
          }
        }
      }
    }
  }
}

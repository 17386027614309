@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-mixins';

.openEndedQuestionRow {
  text-align: start;

  .feedLink {
    a {
      @include zcd-typography.t-body;

      align-items: center;
      color: zcd-colors.$zcd-blue-60;
      display: flex;
      text-align: center;
      text-decoration: none;
      svg {
        margin-inline: 10px 0;
      }
    }
  }
}

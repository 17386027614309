@use '~styles/colors';

.asksManagementPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px;
  width: 100%;

  .workspaceLink {
    color: colors.$highlight;
  }

  > h1 {
    color: colors.$font-color;
    padding: 12px 0;
  }
}

/* stylelint-disable selector-no-qualifying-type,selector-max-compound-selectors */
@use '../../styles/colors';
@use '../../styles/fontSizes';
@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.scoreCardWidget {
  background-color: #fff;
  border: 1px solid colors.$light-gray;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 300px;
  padding: 24px;

  .title {
    display: flex;
  }

  section.percentage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 260px;
    width: 34%;

    > h3 {
      align-items: center;
      display: flex;
      font-size: fontSizes.$font-large;
      .infoIcon {
        color: colors.$color-dodger-blue-dark;
        margin-left: 9px;
      }
    }

    .satisfactionDistribution {
      align-items: flex-start;
      flex-direction: column;
      padding: 0;
      padding-left: 0;

      > :global(.visualDisplay) {
        width: 85%;
      }
    }

    > p {
      color: colors.$info-color;
      font-size: fontSizes.$font-regular;
      line-height: 1.5;
    }

    .totalRespondents {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: row;
        font-size: fontSizes.$font-regular;
        justify-content: space-between;
        line-height: 1.5;
      }
    }

    .bottomInfoItemRespondents {
      @include zcd-typography.t-heading-4;
    }
  }

  .overallScoreGraph {
    flex-grow: 2;
  }

  .bottomInfo {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .bottomInfoItem {
      display: flex;
      flex-direction: column;
    }
  }

  .bottomInfoItemTitle {
    @include zcd-typography.t-heading-6;

    color: zcd-colors.$zcd-gray-60;
  }
}

@use '~styles/colors';
@use '~styles/fontSizes';

.wrapperOverride {
  height: 208px;
  // Override the common-ui Card.Wrapper.
  padding: 24px 24px 18px;
  width: 100%;
  &:hover {
    background-color: colors.$highlight-background;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.customRequestCard {
  display: flex;
  flex-direction: column;
  height: 100%;

  button {
    align-self: flex-start;
    margin-top: auto;
  }

  .icon {
    margin-left: -10px;
    margin-top: -10px;
  }

  .description {
    color: colors.$info-color;
    font-size: fontSizes.$font-small;
    font-weight: 400;
    margin-top: 6px;
  }
}

@use '~styles/zIndexes';

.mapScreen {
  display: flex;
  height: 100%;
  width: 100%;

  > .colorLegend {
    bottom: 0;
    margin-bottom: 24px;
    margin-left: 24px;
    position: absolute;
    z-index: 1;
  }

  > .satisfcationScoresByAreas {
    margin-right: 24px;
    margin-top: 24px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

.loadingSpinner {
  align-items: center;
  display: flex;
  height: 70vh;
  justify-content: center;
}

.breadcrumbsContainer {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-left: 24px;
  margin-top: 24px;
  padding: 8px;
  position: absolute;
  width: fit-content;
  z-index: calc(#{zIndexes.$map-base} + 1);
}

@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.overtimeTrendsIndication {
  align-items: center;
  display: flex;

  .flexCenter {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .label {
    @include zcd-typography.t-body;

    color: zcd-colors.$zcd-gray-60;
    white-space: nowrap;
  }

  .middot::before {
    color: zcd-colors.$zcd-gray-60;
    content: '·';
  }

  .zeroDifference {
    color: zcd-colors.$zcd-gray-60;
    margin: 0 4px;
    white-space: nowrap;
  }

  .iconWrapper {
    > svg {
      font-size: 16px;
    }
  }

  .content {
    width: 60px;
  }

  .spinnerContainer {
    margin: 0 4px;
  }
}

/* stylelint-disable */
// we need to disable stylelint here to export colors and use them within tsx files
:export {
  positive: zcd-colors.$zcd-green-90;
  negative: zcd-colors.$zcd-red-90;
  neutral: zcd-colors.$zcd-gray-80;
}
/* stylelint-enable */

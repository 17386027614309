@use '~styles/colors';
@use '~styles/fontSizes';

.contentEmptyState {
  background-color: transparent;
  margin: 70px auto auto;
  padding: 32px 60px;
  text-align: center;
  h2 {
    font-size: fontSizes.$font-xlarge;
    margin: 0;
    margin-bottom: 8px;
    margin-top: 40px;
  }

  p {
    color: colors.$info-color;
    font-size: fontSizes.$font-regular;
    margin: 0;
    margin-bottom: 24px;
  }

  &.borderless {
    border-style: none;
  }
  button {
    background-color: #fff;
    display: inline-block;
  }
}

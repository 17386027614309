@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

$circle-size: 44px;

.circularProgressbar {
  height: $circle-size;
  width: $circle-size;

  .circularProgressbarInnerText {
    @include zcd-typography.t-heading-5;

    font-size: 12px;
    margin-bottom: calc(#{$circle-size} / 3);
  }
}

/* stylelint-disable */
// we need to disable stylelint here to export colors and use them within tsx files
:export {
  trailColor: zcd-colors.$zcd-gray-20;
}
/* stylelint-enable */

@use '~styles/colors';
@use '~styles/media';

.container {
  padding: 28px;

  &.withRequests {
    background-color: colors.$gray-background-color;
    border-radius: 8px;
    margin: 28px;
    padding: 24px;
  }
}

.typeformExampleCard {
  list-style-type: none;
  > div {
    border: solid 1px colors.$light-gray;
    box-shadow: none;
    width: 100%;
  }
}

.typeformExampleCardList {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 16px;
  padding: 0;

  @media only screen and (max-width: media.$screen-medium-width) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: media.$screen-small-width) {
    grid-template-columns: 1fr;
  }
}

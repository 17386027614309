@use '../../styles/colors';

.surveyRequestStatusBadge {
  background-color: colors.$info-color;
  border-radius: 4px;
  color: colors.$component-background;
  font-weight: 600;
  height: 24px;
  padding: 2px 12px;
  text-align: center;

  &.active {
    background-color: colors.$lavender-color;
  }

  &.closed {
    background: colors.$dark-blue;
  }

  &.completed {
    background-color: #08823d;
  }
}

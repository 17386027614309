@use '~styles/colors';

.expandButton {
  font-size: 14px;
  line-height: 21px;
}

.arrowContainer {
  display: inline-block;
  height: 21px;
  width: 21px;
}

.arrow {
  $border-preset: 1px solid colors.$font-color;

  display: inline-block;
  height: 8px;
  margin-right: 8px;
  transform: rotate(45deg);
  width: 8px;

  &.down {
    border-bottom: $border-preset;
    border-right: $border-preset;
    margin-bottom: 2px;
  }

  &.up {
    border-left: $border-preset;
    border-top: $border-preset;
    margin-bottom: -2px;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.overallWidgets {
  .title {
    @include zcd-typography.t-heading-2;
  }

  .selectedDate {
    color: zcd-colors.$zcd-gray-80;
  }

  .widgetsGrid {
    align-items: stretch;
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2fr;
    justify-items: stretch;

    @media only screen and (max-width: media.$summary-tab-overall-widget-width) {
      grid-column-gap: 0;
      grid-row-gap: 0;
      grid-template-columns: 1fr;
    }
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.topicSection {
  margin-inline-end: 24px;
  padding: 16px 0;
  width: 100%;
}

.topic {
  @include zcd-typography.t-heading-5;

  cursor: pointer;
  text-align: start;

  &.disabled {
    cursor: auto;
  }
}

.responses {
  @include zcd-typography.t-body;

  color: zcd-colors.$zcd-gray-80;
  margin-block-end: 8px;
}

@import '~styles/colors';

$border-radius: 8px;

.container {
  padding: 28px;
}

.surveysList {
  border: 1px solid $light-gray;
  border-radius: $border-radius;
  overflow: hidden;
}

.heading {
  margin-bottom: 16px;
}

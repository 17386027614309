@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.questionText {
  @include zcd-typography.t-body;

  text-align: start;
}

.topResponse {
  @include zcd-typography.t-small;

  color: zcd-colors.$zcd-gray-60;
}

.submissionsPercentage {
  @include zcd-typography.t-heading-4;
}

.choiceText {
  @include zcd-typography.t-body;

  text-align: start;
}

.middot::before {
  color: zcd-colors.$zcd-gray-60;
  content: '·';
}

.responseContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: media.$results-info-section-width;
  padding: 8px;
  width: media.$results-info-section-width;
}

.flexCenter {
  align-items: center;
  display: flex;
}
p {
  text-align: start;
}

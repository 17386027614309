@use '~styles/colors';
@use '~styles/media';

@media screen and (max-width: media.$community-ask-widget-width-small-screen) {
  .communityAsksReportCanvas {
    canvas {
      height: auto !important;
      width: 100% !important;
    }
  }
}

.initialCommunityAsksReportCanvas {
  visibility: hidden;
  width: 0;
}

.communityAsksReportCanvas {
  padding-bottom: 28px;
  canvas {
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.communityAsksReportTab {
  display: flex;
  justify-content: center;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 28px;

  .report {
    max-width: media.$community-ask-widget-max-width;
  }
}

.communityAsksReportButton {
  text-align: center;
  .button {
    margin-top: 24px;
    padding: 0 16px;
    > span {
      background-color: colors.$component-background;
      color: colors.$highlight;
      margin-left: unset;
      margin-right: 13px;
    }
  }
  .saButton {
    align-items: center;
    margin-bottom: 24px;
    margin-top: unset;
    > span {
      align-items: center;
      background-color: colors.$highlight;
      color: colors.$component-background;
    }
  }
}

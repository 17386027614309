@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-mixins';

.crossQuestionTable {
  table {
    tr {
      td {
        border-left-width: 0;
        min-width: 92px;
        &:first-child {
          border-right: 2px solid zcd-colors.$zcd-gray-40;
        }
      }
    }
    thead {
      th {
        border-left-width: 0;
        &:first-child {
          border-right: 2px solid zcd-colors.$zcd-gray-40;
        }
      }
      tr:last-child {
        th:not(:first-child) {
          background-color: zcd-colors.$zcd-blue-10;
        }
      }
    }
    .cellWrapper {
      height: 48px;
    }
  }
}
.noData {
  @include zcd-typography.t-body;

  color: zcd-colors.$zcd-gray-60;
  text-align: center;
}

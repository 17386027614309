@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.aspectWidgetTab {
  color: zcd-colors.$zcd-gray-60;
  height: 100%;
  min-width: 260px;
  padding: 24px;
  width: 100%;

  .score {
    @include zcd-typography.t-heading-1;

    color: zcd-colors.$zcd-gray-60;
  }

  &.active {
    border-bottom: 6px solid zcd-colors.$zcd-blue-60;
    color: zcd-colors.$zcd-gray-100;

    .score {
      color: zcd-colors.$zcd-gray-100;
    }
  }

  &:hover {
    background-color: zcd-colors.$zcd-gray-30;
    cursor: pointer;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.totalSubmissionsWidget {
  height: 100%;
  padding: 8px;

  .title {
    @include zcd-typography.t-heading-5;

    color: zcd-colors.$zcd-gray-80;
  }

  .totalSubmissions {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
  }

  .comparisonTitle {
    @include zcd-typography.t-small;

    color: zcd-colors.$zcd-gray-60;
  }

  .loaderMask {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    width: 100%;
  }
}

@use '~styles/colors';
@use '~styles/fontSizes';
@use '~styles/media';

.verticalMenu {
  background-color: colors.$component-background;
  border-right-color: colors.$light-gray;
  border-right-style: solid;
  border-right-width: 1px;
  width: 290px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      border-bottom-color: colors.$light-gray;
      border-bottom-width: 1px;
      border-style: none;
      border-bottom-style: solid;
      &:last-child {
        border-bottom-style: none;
      }
    }
  }
  &.fixed {
    position: sticky;
    top: 0;
  }
}

.placeholder {
  min-width: 290px;
}

@media only screen and (max-width: media.$screen-sm-width) {
  .verticalMenu {
    display: none;
  }

  .placeholder {
    display: none;
  }
}

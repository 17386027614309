@use '~styles/colors';
@use '~styles/fontSizes';

.title {
  color: colors.$info-color;
  font-size: fontSizes.$font-xsmall;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: uppercase;
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.resultsQuestionSummary {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 1rem;

  // setting grid to 3 full width rows
  @media only screen and (max-width: media.$screen-medium-width) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.noScoresQuestionLayout {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 1rem;

  // setting grid to 2 full width rows
  @media only screen and (max-width: media.$screen-medium-width) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.divisionListItem {
  align-items: center;
  display: flex;
  grid-area: 1 / 2 / 2 / 3;
  justify-content: space-between;
  padding: 24px 0;
  width: 100%;

  .typography {
    @include zcd-typography.t-heading-5;
  }
}

@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.score {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
}

.comparisonScore {
  @include zcd-typography.t-heading-4;
}

.bold {
  @include zcd-typography.t-heading-4;
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

/* stylelint-disable */
// we need to disable stylelint here to export colors and use them within tsx files
:export {
  positiveColor: zcd-colors.$zcd-color-bg-positive;
  negativeColor: zcd-colors.$zcd-color-bg-negative;
  topChoiceColor: zcd-colors.$zcd-gray-60;
}

.summaryWidget {
  height: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
  .wrapper {
    padding: 4px;
  }
  > * {
    padding: 0;
  }
  .widgetLabel {
    @include zcd-typography.t-heading-5;

    display: block;
    margin-bottom: 12px;
  }
  .comparisonLabel {
    @include zcd-typography.t-heading-6;

    color: zcd-colors.$zcd-gray-80;
  }
  .progressBarWrapper {
    margin-top: 4px;
  }
  .comparison {
    width: 46%;
  }

  .score {
    width: media.$results-progress-bar-width;
  }

  .responsiveScore {
    width: 100%;
    @media only screen and (max-width: media.$screen-medium-width) {
      width: media.$results-progress-bar-width;
    }
  }
}

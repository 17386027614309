@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.barScore {
  margin-bottom: 4px;
  width: 100%;
}

.value {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}
.ratio {
  @include zcd-typography.t-heading-6;

  color: zcd-colors.$zcd-gray-80;
  margin-inline: 8px 0;
  width: 100%;
}

.progressBarWrapper {
  width: media.$results-progress-bar-width;
  @media only screen and (max-width: media.$screen-medium-width) {
    width: 100%;
  }
}

@use '../../../styles/colors';
@use '../../../styles/fontSizes';

.satisfactionScoresByAreaList {
  margin-bottom: 8px;
  margin-top: 20px;
  overflow-y: scroll;
  width: 100%;
}

.listHeaderText {
  align-items: center;
  color: colors.$info-color;
  display: flex;
  font-size: fontSizes.$font-xsmall;
  height: 16px;
  justify-content: space-between;
  letter-spacing: 1px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: var(--padding-standard);
  padding-right: var(--padding-standard);
  text-transform: uppercase;
}

.checkboxContainer {
  display: flex;
  > input {
    margin: 0 12px 0 0;
  }
}

@use '~styles/colors';
@use '~styles/fontSizes';

.requestFormHeader {
  align-items: center;
  background-color: #f5f6fc;
  box-shadow: 0 1px 0 colors.$light-gray;
  display: flex;
  height: 248px;
  justify-content: center;

  textarea {
    border: 1px solid colors.$light-gray;
    border-radius: 4px;
    box-sizing: border-box;
    font: inherit;
    height: 81px;
    margin-bottom: 12px;
    padding: 8px 16px;
    resize: none;
    width: 493px;
  }
}

.iconContainer {
  height: inherit;
  margin-right: 43px;
  overflow: hidden;
  position: relative;
  width: 280px;
  .icon {
    height: 248px;
    left: -30px;
    position: relative;
    width: 365px;
  }
}

.subHeader {
  font-weight: 100;
  margin-bottom: 8px;
}

.requestForm {
  height: 100%;
  padding-top: 36px;
}

.actionsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.helpArticleLink {
  color: colors.$highlight;
  font-size: fontSizes.$font-regular;
  text-decoration: none;
}

$font-xlarge: var(--font-xlarge);
$font-large: var(--font-large);
$font-regular: var(--font-regular);
$font-small: var(--font-small);
$font-xsmall: var(--font-xsmall);
$font-tiny: var(--font-tiny);

body {
  --font-xlarge: 20px;
  --font-large: 16px;
  --font-regular: 14px;
  --font-small: 12px;
  --font-xsmall: 10px;
  --font-tiny: 8px;
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.blockwiseSummaryTab {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 120px;
  width: 100%;

  @media only screen and (max-width: media.$screen-large-width) {
    padding: 24px 90px;
  }

  @media only screen and (max-width: media.$screen-medium-width) {
    padding: 24px 48px;
  }

  @media only screen and (max-width: media.$screen-small-width) {
    padding: 24px;
  }
}

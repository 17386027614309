@use '~styles/colors';
@use '~styles/fontSizes';

.typeformExampleRequestForm {
  background-color: colors.$component-background;
  border-right-color: colors.$light-gray;
  border-right-style: solid;
  border-right-width: 1px;
  height: 100%;
  padding: 28px;
  width: 373px;
  &.fixed {
    position: fixed;
  }

  .box {
    border: 1px solid colors.$light-gray;
    border-radius: 8px;
    padding: 16px;
  }

  .description {
    box-sizing: border-box;
    display: inline-block;
    font-size: fontSizes.$font-regular;
    font-weight: 400;
    line-height: 21px;
    padding-bottom: 32px;
    padding-top: 8px;
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .detailsBox {
    display: flex;
    flex-direction: column;
    height: 92px;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 154px;

    > h2 {
      font-size: fontSizes.$font-xlarge;
    }
  }

  .lighter {
    color: colors.$info-color;
  }

  .requestBox {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 80px;
    margin-bottom: 32px;
    width: 100%;
  }

  h5 {
    color: colors.$info-color;
    font-size: fontSizes.$font-small;
    font-weight: 400;
    line-height: 18px;
  }
}

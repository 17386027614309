@use '~/src/styles/fonts';
@use '~styles/colors';

.header {
  padding-bottom: 8px;
}

.titleWrapper {
  align-items: center;
  display: flex;
}

.title {
  font-size: fonts.$font-xl;
  font-weight: 600;
  line-height: 36px;
  margin-right: 12px;
}

.status {
  margin-left: 12px;
}

.date {
  color: colors.$info-color;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  margin-top: 4px;
}

.tabsAndActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .actionButtons {
    align-self: start;
    display: flex;
    > button {
      padding-right: 24px;
    }

    .shareResultsWrapper {
      margin-left: 12px;
      position: relative;

      > button {
        padding-left: 24px;

        &:focus {
          background: colors.$highlight;
        }

        > svg {
          margin-left: 14px;
          transition: transform 0.3s ease-in-out;
        }
      }

      &.toggled {
        > button {
          > svg {
            transform: rotate(180deg);
          }
        }
      }

      .shareResultsDropdown {
        background-color: colors.$component-background;
        border: 1px solid colors.$light-gray;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        position: absolute;
        right: 0;
        top: 48px;
        width: 224px;
      }
    }
  }
}

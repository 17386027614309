@use '~styles/colors';

.exportSubmissionsWrapper {
  position: relative;

  .exportSubmissionsButton {
    background: colors.$component-background;
    border-radius: 0;
    color: colors.$font-color;
    height: 36px;
    justify-content: initial;
    padding: 4.5px 16px;
    width: 100%;

    &:hover {
      background-color: colors.$gray-background-color;
    }

    > svg {
      margin-right: 8px;
    }
  }

  .spinnerWrapper {
    left: 3px;
    position: absolute;
    top: 5px;
  }
}

@import '~styles/media';

.summary {
  margin: 28px auto;
  max-width: $community-ask-widget-max-width;
  width: 100%;

  > div {
    // Each question row.
    padding-bottom: var(--padding-standard);
    padding-top: var(--padding-standard);
  }
}

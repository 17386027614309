@use '~styles/colors';

.downloadReportLink {
  font-size: 14px;
  height: 36px;
  padding: 3.5px 16px;
  text-decoration: none;

  &:hover:not(.disabled) {
    background-color: colors.$gray-background-color;
  }

  &.disabled {
    color: colors.$info-color;
    cursor: not-allowed;
    opacity: 0.65;

    &:active {
      pointer-events: none;
    }
  }

  > svg {
    margin-right: 8px;
    vertical-align: bottom;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.tooltipTitle {
  @include zcd-typography.t-heading-6;

  color: zcd-colors.$zcd-gray-80;
}

.scoreTitleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .scoreTitle {
    @include zcd-typography.t-heading-6;

    color: zcd-colors.$zcd-gray-100;
  }

  .scoreTitleValue {
    @include zcd-typography.t-action-small;
  }
}

/* stylelint-disable */
// we need to disable stylelint here to export colors and use them within tsx files
:export {
  positive: zcd-colors.$zcd-green-40;
  negative: zcd-colors.$zcd-red-90;
  neutral: zcd-colors.$zcd-gray-80;
}
/* stylelint-enable */

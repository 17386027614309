@use '~styles/colors';
@use '~styles/media';

/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
.screen {
  display: flex;
  justify-content: center;
  margin: 24px 28px 0;

  .summary {
    display: grid;
    max-width: media.$screen-large-width;

    :global(.screenLayoutLarge) & {
      width: media.$screen-large-width;
    }
    :global(.screenLayoutMedium) & {
      width: media.$screen-medium-width;
    }
    :global(.screenLayoutSmall) & {
      width: media.$screen-small-width;
    }
    :global(.screenLayoutMobileLarge) & {
      width: media.$mobile-large-width;
    }
    :global(.screenLayoutMobileSmall) & {
      width: media.$mobile-small-width;
    }

    > section {
      column-gap: 30px;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin-bottom: 24px;
      > h2 {
        font-size: 20px;
        font-weight: 600;
        grid-column: 1/13;
        margin-bottom: 16px;
      }
      .overallSatisfaction {
        background-color: #f99;
        grid-column: 1/9;
        height: 265px;
      }
      .organicDiscourse {
        background-color: #f99;
        grid-column: 9/13;
        height: 265px;
      }
      .addOnSectionTitle {
        .infoIcon {
          color: colors.$color-dodger-blue-dark;
          margin-left: 9px;
        }
      }
      .aspect {
        grid-column: 1/13;
      }
      .topDemographics {
        grid-column: 1/7;
        margin-bottom: 24px;
      }
      .bottomDemographics {
        grid-column: 7/13;
        margin-bottom: 24px;
      }
      .areaBreakdown {
        grid-column: 1/13;
        margin-bottom: 24px;
      }
      .questions {
        grid-column: 1/13;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
      &.nextSteps {
        /* stylelint-disable-line */
        background-color: #ececec;
        border-radius: 8px;
        height: 283px;
        .takeAnAction {
          /* stylelint-disable-line */
          grid-column: 1/13;
        }
      }
    }
  }
}

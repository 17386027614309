@use '~styles/colors';

.errorBoundaryContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > p {
    color: colors.$info-color;
    margin: 4px 0;
    max-width: 50%;
  }
}

.iconContainer {
  border-left: 1px solid #e0e0e0;
  height: inherit;
  overflow: hidden;

  > .icon {
    height: auto;
    transform: scale(1.5);
    width: 100%;
  }
}

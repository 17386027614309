@use '~styles/fontSizes';
@use '~styles/colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.satisfactionDistribution {
  align-items: center;
  display: flex;
  padding: 8px 0 8px 8px;

  .percentageDisplay {
    &.small {
      @include zcd-typography.t-heading-6;
    }

    &.regular {
      @include zcd-typography.t-heading-4;
    }

    &.large {
      font-size: 36px;
    }
  }

  .visualDisplay {
    display: flex;
    gap: 2px;
    height: 6px;
    width: 144px;

    .positive {
      background-color: colors.$zcd-green-40;
    }
    .negative {
      background-color: #ff4c59;
    }
    .nonSubstantiative {
      background-image: repeating-linear-gradient(-45deg, transparent 1px 4px, zcd-colors.$zcd-gray-40 0 5px);
      border: solid 1px zcd-colors.$zcd-gray-40;
    }
    .neutral {
      background-color: #d8d8d8;
    }
    span:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    span:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.concernLabel {
  @include zcd-typography.t-heading-5;
}

/* stylelint-disable */
// we need to disable stylelint here to export colors and use them within tsx files
:export {
  pathColor: zcd-colors.$zcd-red-30;
}
/* stylelint-enable */

@use '~styles/colors';
@use '~styles/fontSizes';

$side-margin: 30px;

.emptyStateGraphSection {
  color: colors.$info-color;
  display: flex;
  flex-direction: column;
  font-size: fontSizes.$font-xsmall;
  height: 100%;
  justify-content: space-between;

  .notEnoughDataMessage {
    max-width: 276px;
  }

  .topBoundary,
  .bottomBoundary {
    align-items: center;
    display: flex;
    flex-direction: row;

    > span {
      width: $side-margin;
    }
  }

  .graph {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: fontSizes.$font-small;
    padding-left: $side-margin;
    text-align: center;
  }

  .graphBottomWrapper {
    display: flex;
    flex-direction: column;
    height: 37px;
    justify-content: space-between;

    .legend {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: $side-margin;
    }
  }
}

@use '~styles/colors';
@use '~styles/fontSizes';

.container {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 28px;
}

.label {
  color: colors.$info-color;
  font-size: fontSizes.$font-large;
  font-weight: 600;
  line-height: 24px;
}

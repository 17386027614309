@use '@zencity/common-ui/lib/zcd-colors';

.overallScoreGraph {
  height: 100%;
}

/* stylelint-disable */
// we need to disable stylelint here to export colors and use them within tsx files
:export {
  green: zcd-colors.$zcd-green-40;
}
/* stylelint-enable */

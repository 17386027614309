.loaderMaskWrapper {
  position: relative;
}

.loaderMask {
  align-items: center;
  background-color: #fffc;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 100;
}

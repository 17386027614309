@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.aspectWidgetTabs {
  align-items: center;
  background-color: zcd-colors.$zcd-gray-10;
  border: 1px solid zcd-colors.$zcd-gray-40;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  height: 127px;

  .tabs {
    display: flex;
    height: 100%;
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;

    > button {
      border-right: 1px solid #d8d8d8;
    }
  }
}

@use '~styles/colors';
@use '~styles/fontSizes';

li {
  list-style-type: none;
}

.satisfactionScoresByAreaRow {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 56px;
  justify-content: space-between;
  width: 100%;

  &.rowContent {
    height: 21px;
    padding-left: 20px;
    padding-right: var(--padding-standard);
  }

  &:hover {
    background-color: colors.$highlight-background;
    &.unchecked {
      input {
        border-color: colors.$highlight;
      }
    }
    &.checked {
      input {
        background-color: colors.$lavender-color;
        border-color: colors.$lavender-color;
      }
    }
  }
}

.checkboxContainer {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 21px;
}

.rowSatisfactionDistribution {
  margin-left: auto;
  max-width: 140px;
}

.areaColor {
  height: 56px;
  min-width: 4px;
  position: absolute;
}

.noResponses {
  color: colors.$info-color;
  font-size: fontSizes.$font-small;
  font-weight: 400;
}

$light-gray: var(--light-gray);
$topic-background: #e2eaff;
$info-color: var(--info-color);
$page-background: #e5e5e5;
$component-background: #fff;
$font-color: #222;
$highlight: #3463fe;
$color-dodger-blue-dark: var(--color-dodger-blue-dark); // is defined at common-ui: #3463fe
$highlight-background: #f5f6fc;
$extra-info: #5c5c5c;
$very-light-azure: var(--very-light-azure);
$light-azure: var(--light-azure);
$gray-background-color: var(--gray-background-color);
$toast-green: #058b5b;
$dark-blue: var(--dark-blue);
$lavender-color: var(--lavender-color);
$dark-pink: var(--dark-pink);
$zcd-green-40: var(--zcd-green-40);

:root {
  --dark-blue: #0030e2;
  --dark-pink: #e5cfe9;
  --lavender-color: #6681ff;
  --gray-background-color: #ececec;
  --light-gray: #d8d8d8;
  --info-color: #6d6d6d;
  --very-light-azure: #f5f7fc;
  --light-azure: #e2ebff;
  --zcd-green-40: #24d394;
}

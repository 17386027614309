@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-mixins';

.breakdownTable {
  tr td {
    border-left: 2px solid zcd-colors.$zcd-gray-40;
    padding: 0;

    &:first-child {
      width: 240px;
    }
    &:last-child {
      background-color: zcd-colors.$zcd-blue-10;
    }
  }
}

.borderLeft {
  border-left: 1px solid zcd-colors.$zcd-gray-40;
}

.changeValue {
  @include zcd-typography.t-heading-4;
}

@use '~styles/colors';
@use '~styles/fontSizes';

.screenWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .lighter {
    color: colors.$info-color;
  }
}

.mainWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  .previewArea {
    flex: 1;
    height: 100%;
    padding: 32px 64px 64px;

    .survey {
      height: 100%;
      padding-top: 32px;
      width: 100%;
    }

    > h2 {
      font-size: fontSizes.$font-xlarge;
      font-weight: 600;
    }
  }
}

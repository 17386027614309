@use '~styles/colors';
@use '~styles/fontSizes';

.asksManagementTable {
  border-collapse: collapse;
  font-size: fontSizes.$font-small;
  min-width: 400px;
  width: 100%;

  .linkToSurveyPage {
    text-decoration: none;
  }

  // override the style from Common UI
  .overrideBtnStyle {
    font-size: fontSizes.$font-small;
    padding: 0;
  }

  // override the style from Common UI
  .overrideBtnStyle:disabled {
    font-size: fontSizes.$font-small;
  }

  > thead tr {
    background-color: colors.$light-azure;
    border: 1px solid colors.$page-background;
    border-radius: 4px;
    color: colors.$info-color;
    text-align: left;
  }

  > tbody tr {
    border-bottom: 1px solid colors.$highlight-background;
  }

  > tbody tr:nth-of-type(even) {
    background-color: colors.$page-background;
  }

  th,
  td {
    max-width: 250px;
    padding: 8px;
    text-transform: capitalize;
    word-wrap: break-word;
  }

  .copySurveyPublicLink {
    text-align: center;
    width: 50px;
  }
}

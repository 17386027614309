@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

$icon-dimensions: 16px;

.breadcrumbs {
  align-items: center;
  display: flex;

  .crumb {
    align-items: center;
    display: flex;

    i,
    svg {
      height: $icon-dimensions;
      width: $icon-dimensions;
    }
  }

  .buttonWrapper {
    > button {
      @include zcd-typography.t-mini;

      color: zcd-colors.$zcd-gray-100;
    }
  }

  .disabledButtonWrapper {
    > button {
      color: zcd-colors.$zcd-gray-80;
    }
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.demographicGroupItem {
  display: flex;
  width: 100%;

  .label {
    @include zcd-typography.t-heading-6;
  }
}

.noScoreTitle {
  @include zcd-typography.t-body;

  color: zcd-colors.$zcd-gray-60;
}

/* stylelint-disable */
// we need to disable stylelint here to export colors and use them within tsx files
:export {
  pathColor: zcd-colors.$zcd-green-40;
}
/* stylelint-enable */

.wrapperOverride {
  // overrides the default settings from common-ui
  height: 208px;
  overflow: hidden;
  padding: 0;
  width: 100%;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .thumbnail {
    height: 140px;
    object-fit: cover;
    width: 100%;
  }

  .bookmark {
    align-items: center;
    display: flex;
    height: 68px;
    justify-content: space-between;
    overflow: auto;
    padding: 10px;

    > h2,
    .previewLink {
      font-size: 14px;
    }
  }
}

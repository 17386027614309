@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

$demographic-groups-header-height: 64px;

.demographicGroups {
  background-color: zcd-colors.$zcd-gray-10;
  border: 1px solid zcd-colors.$zcd-gray-40;
  border-radius: 8px;
  grid-area: 1 / 2 / 2 / 3;
  height: 300px;
  width: 100%;

  @media only screen and (max-width: media.$screen-xlarge-width) {
    grid-area: 2 / 1 / 3 / 4;
  }

  .content {
    height: calc(100% - #{$demographic-groups-header-height});
    overflow-y: auto;
    padding: 24px;
  }

  .divider {
    border-bottom: 1px solid zcd-colors.$zcd-gray-40;
    margin: 12px 0;
    width: 100%;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.container {
  padding: 5px 10px;
}

.title {
  margin-bottom: 40px;
}

.colorLegend {
  border-radius: 1px;
  height: 10px;
  width: 35px;

  &:not(:last-child) {
    margin-inline-end: 2px;
  }
}

.legendText {
  @include zcd-typography.t-heading-6;
}

.barsContainer {
  margin-bottom: 4px;
  margin-top: 10px;
}

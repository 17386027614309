@use '@zencity/common-ui/lib/zcd-colors';
@use '~styles/media';

.screen {
  display: flex;
  height: calc(100vh - #{media.$header});
  overflow: hidden;
}

.questions {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1340px;
  overflow: auto;
  padding: 24px;
  width: 120vh;
}
.emptyStateIcon {
  border-radius: 50%;
  // Changes only the background color.
  > rect:first-of-type {
    fill: zcd-colors.$zcd-purple-20;
  }
}

.loadingSpinner {
  align-items: center;
  display: flex;
  height: 70vh;
  justify-content: center;
}

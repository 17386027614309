@use '~styles/colors';

.superAdminBadge {
  background: #024eb5;
  border-radius: 50%;
  color: colors.$component-background;
  font-size: 7px;
  font-weight: 600;
  margin-left: 4px;
  padding: 3px;
  text-transform: uppercase;
  vertical-align: middle;
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.overallWidgetWrapper {
  display: inherit;
  height: 200px;
  margin: 16px 0;

  > div > div {
    height: 100%;
  }
}

@use '~styles/colors';

.managementLink {
  border-radius: 5px;
  color: colors.$highlight;
  padding: 5px;
  position: fixed;
  right: 20px;
  text-decoration: none;
  top: 20px;
  z-index: 1031;

  &:hover {
    background-color: colors.$gray-background-color;
  }
}

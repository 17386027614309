@use '~styles/colors';

$border-radius: 8px;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 28px;
}

.surveyRequestsList {
  border: 1px solid colors.$light-gray;
  border-radius: $border-radius;
  overflow: hidden;
  width: 100%;

  .surveyRequestsItem {
    &:not(:last-child) {
      border-bottom: 1px solid colors.$light-gray;
    }
  }
}

.heading {
  margin-bottom: 16px;
}

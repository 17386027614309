@use '~/src/styles/fonts';
@use '~styles/zIndexes';
@use '~styles/media';

.header {
  align-items: center;
  display: flex;
  padding-bottom: 8px;
}

.icon {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
}

.title {
  font-size: fonts.$font-xl;
  font-weight: 600;
  line-height: 36px;
  margin-left: 16px;
}

.headerContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.filters {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: start;
  margin: 24px 0;
}

// we need to wrap the sticky header and create stickiness of our own to make sure
// we're under the z-index of the filters
.headerContainer {
  position: sticky;
  top: 0;
  z-index: zIndexes.$header;
  > div {
    height: media.$header;
  }
}

.wrapper {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 33%;
  margin-left: 33%;
  margin-top: 20%;
  row-gap: 10px;
  text-align: center;
  width: 33%;
  a {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 20px 10px;
    text-decoration: none;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.methodology {
  height: 100%;
  padding: 8px;

  .title {
    @include zcd-typography.t-heading-5;

    color: zcd-colors.$zcd-gray-80;
  }

  .description {
    @include zcd-typography.t-small;
  }
}

@use '~styles/fontSizes';
@use '~styles/colors';

.copySurveyPublicLinkWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .displayedLink {
    border: 1px solid colors.$highlight;
    border-bottom-left-radius: 20px;
    border-right: 0;
    border-top-left-radius: 20px;
    box-sizing: border-box;
    color: colors.$info-color;
    max-width: 150px;
    overflow-x: hidden;
    padding: 5.5px 16px;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  .copySurveyPublicLink {
    cursor: pointer;
    font-size: fontSizes.$font-large;
    transition: color 0.3s ease-in-out;

    &.btnDecoration {
      border: 1px solid colors.$highlight;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      box-sizing: border-box;
      color: colors.$highlight;
      padding: 6px 20px 6px 16px;
    }

    &:hover {
      &.btnDecoration {
        background-color: colors.$light-azure;
      }

      &:not(.btnDecoration) {
        color: colors.$info-color;
      }
    }

    &.copied {
      color: colors.$toast-green;
      position: relative;

      &::after {
        bottom: -11px;
        color: colors.$toast-green;
        content: 'copied!';
        font-size: fontSizes.$font-xsmall;
        left: 16px;
        position: absolute;
        transition: all 0.1s ease-in-out;
      }

      &:not(.btnDecoration) {
        &::after {
          bottom: -7px;
          left: 0;
        }
      }
    }
  }
}

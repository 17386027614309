@use '~styles/colors';
@use '~styles/fontSizes';
@use '~styles/media';

.questionBox {
  margin-bottom: 24px;

  .questionTitle {
    font-size: fontSizes.$font-xlarge;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    margin-bottom: 4px;
  }
  .answerCounter {
    color: colors.$extra-info;
    font-size: fontSizes.$font-small;
    line-height: 18px;
  }
}

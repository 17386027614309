@use '~styles/colors';
@use '~styles/fontSizes';

.contentPlaceholder {
  background-color: colors.$component-background;
  border: 1px solid colors.$light-gray;
  border-radius: 8px;
  padding: 32px 60px;
  text-align: center;

  h2 {
    font-size: fontSizes.$font-large;
    margin: 0;
    margin-bottom: 8px;
  }

  p {
    color: colors.$info-color;
    font-size: fontSizes.$font-regular;
    margin: 0;
  }

  &.borderless {
    border-style: none;
  }
}

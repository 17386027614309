@use '~styles/colors';
@use '~styles/fontSizes';
@use '~styles/media';

.answerList {
  display: flex;
  flex-direction: column;
}

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: media.$screen-sm-width) {
  .answerList {
    margin-top: 28px;
  }
}

@use '~styles/media';

.scoreCardSection {
  .scoreCardSectionContent {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    @media (max-width: media.$screen-large-width) {
      grid-template-columns: 1fr;
    }

    .overallSatisfaction {
      grid-area: 1 / 1 / 2 / 3;
      @media (max-width: media.$screen-large-width) {
        grid-area: auto;
      }
    }

    .organicDiscourse {
      grid-area: 1 / 3 / 2 / 4;
      @media (max-width: media.$screen-large-width) {
        grid-area: auto;
      }
    }
  }
}

@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.choiceHeader {
  white-space: nowrap;
}

.choiceText {
  @include zcd-typography.t-heading-4;

  white-space: normal;
}

@use '~styles/colors';
@use '~styles/fontSizes';

.linkAsButton {
  background-color: colors.$color-dodger-blue-dark;
  border-radius: 100px;
  color: colors.$component-background;
  font-size: fontSizes.$font-large;
  padding: 6px 24px;
  text-decoration: none;
}

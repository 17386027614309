@use '@zencity/common-ui/lib/zcd-colors';

.dot {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.positive {
  background-color: zcd-colors.$zcd-green-40;
}

.negative {
  background-color: zcd-colors.$zcd-red-30;
}

.neutral {
  background-color: zcd-colors.$zcd-gray-50;
}

.nonSubstantiative {
  background-image: repeating-linear-gradient(-45deg, transparent 1px 4px, zcd-colors.$zcd-gray-40 0 5px);
  border: solid 1px zcd-colors.$zcd-gray-40;
}

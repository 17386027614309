/* 
* These HeaderTabs use the same css as the TabItems in Common UI.
*
* We moved the CSS to here because Common UI also implements logic, 
* not just styling. The logic handles which tab is active and
* expects infomation we do not need/want to pass now that we are 
* using NavLinks to handle the active tab logic for us. 
*/
@use '~styles/colors';
@use '~styles/fontSizes';

.tabs {
  display: flex;
  flex-direction: row;
  margin-bottom: -1px;

  .tabItem {
    border: 2px solid transparent;
    color: colors.$font-color;
    cursor: pointer;
    font-size: fontSizes.$font-regular;
    margin-top: auto;
    padding: 16px;
    text-decoration: none;

    &:hover {
      background-color: colors.$very-light-azure;
      border-bottom: solid 1px colors.$light-gray;
      padding-bottom: 17px;
    }

    &.active {
      border-bottom: 2px solid colors.$highlight;
      padding-bottom: 16px;
    }
  }
}

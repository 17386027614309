@use '~styles/colors';
@use '~styles/fontSizes';

.menuOption {
  background-color: colors.$component-background;
  color: colors.$font-color;
  cursor: pointer;
  font-size: fontSizes.$font-regular;
  height: 100%;
  line-height: 21px;
  padding: 16px 28px;
  text-align: inherit;
  width: 100%;

  &.selected {
    background: colors.$highlight-background;
    font-weight: 600;
  }
}

@use '~styles/media';
@use '~styles/colors';
@use '~styles/fontSizes';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.breakdownRow {
  align-items: center;
  display: flex;
  height: media.$breakdown-row-height;
  justify-content: space-between;
  text-align: start;
  width: 100%;

  p {
    font-size: 14px;
  }

  .noScoreTitle {
    @include zcd-typography.t-body;

    color: zcd-colors.$zcd-gray-60;
  }
}

.flexCenter {
  align-items: center;
  display: flex;
}

.rowText {
  text-align: start;
}

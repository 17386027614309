@use '~styles/media';
/* stylelint-disable */
@import '../MapGraphWidget/WidgetBox/WidgetBox.module';
/* stylelint-enable */

$navigation-control-extra-spacing: 24px;

.widgetView {
  height: 100%;
  width: 100%;
}

.screenView {
  height: calc(100vh - #{media.$header});
  width: 100%;

  //stylelint-disableselector-class-pattern
  :global(.mapboxgl-ctrl-bottom-left) {
    // The Mapbox logo has to shown in the map, as part of Mapbox's Terms of Service.
    // (https://docs.mapbox.com/help/getting-started/attribution/#mapbox-logo).
    left: auto;
    margin-bottom: 24px;
    // We want the logo to be shown to the left of the help button on the client view, so the calculated margin is
    //the help button's margin-right + the button's width + 24px margin from the button.
    margin-right: calc(24px + 60px + 16px);
    position: fixed;
    right: 0;
  }
}

.baseNavigationControl {
  padding-right: 24px;
  padding-top: 24px;
  position: fixed;
  right: 0;
}

.screenNavigationControl {
  margin-right: calc($widget-box-width + $navigation-control-extra-spacing);
}

.summaryWidgetNavigationControl {
  margin-right: 'auto';
}

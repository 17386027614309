@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.divisionList {
  grid-area: 1 / 2 / 2 / 3;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 24px;

  @media only screen and (max-width: media.$screen-medium-width) {
    grid-area: 1 / 1 / 2 / 2;
  }

  > div:not(:last-child, :first-child) {
    border-bottom: 1px solid zcd-colors.$zcd-gray-40;
  }
  > div:first-child {
    border-bottom: 1px dashed zcd-colors.$zcd-gray-40;
  }
}

@use '~styles/colors';
@use '~styles/media';

.feedWrapper {
  display: flex;
  flex-direction: row;
  height: calc(100vh - #{media.$header});

  .openQuestionFeed {
    flex: 1;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 604px;
    overflow: auto;
    padding: 28px;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
  }

  .leadingTopicsWrapper {
    flex: 0 0 300px;
    margin-right: auto;

    .topSpacer {
      height: 108px;
    }
  }
}

@media only screen and (max-width: media.$screen-sm-width) {
  .feedWrapper {
    flex-direction: column-reverse;
    height: 100%;
    overflow: auto;
    padding: 28px;

    .openQuestionFeed {
      margin: auto;
      padding: unset;
      width: 95%;
    }

    .leadingTopicsWrapper {
      margin: 16px;
      width: unset;

      .topSpacer {
        display: none;
      }
      .widget {
        position: unset;
      }
    }
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-mixins';

.crossQuestionMenu {
  background-color: zcd-colors.$zcd-gray-10;
  border: 1px solid zcd-colors.$zcd-gray-40;
  max-width: 300px;
  padding: 24px;
  position: sticky;
  top: 0;
}

.menuTitle {
  @include zcd-typography.t-heading-3;
}

.menuSubtitle {
  @include zcd-typography.t-small;

  color: zcd-colors.$zcd-gray-80;
}

.noQuestionTitle {
  @include zcd-typography.t-body;
}

.closeBtn {
  cursor: pointer;
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-mixins';
@use '~styles/media';

.resultsQuestionsBreakdown {
  border-radius: 0;
  p {
    @include zcd-typography.t-body;
  }
  .header {
    .questionNumber {
      @include zcd-typography.t-heading-5;

      align-items: center;
      background-color: zcd-colors.$zcd-purple-20;
      border-radius: 50%;
      color: zcd-colors.$zcd-purple-100;
      display: flex;
      height: 48px;
      justify-content: center;
      margin-inline: 0 18px;
      min-width: 48px;
      text-align: start;
      width: 48px;
    }

    button {
      @include zcd-mixins.clickable;

      align-items: center;
      display: flex;
      height: 85px;
      justify-content: flex-start;
      width: 100%;

      li > div:nth-child(2) {
        min-width: media.$results-info-section-width;
      }

      i {
        @include zcd-mixins.rotate-base;

        display: block;
      }
    }
    .openEndedButton {
      cursor: default;
    }
    &.expanded {
      display: block;
    }
  }
  .questionRow {
    width: 100%;
  }
  .expandButton {
    &.expanded {
      i {
        @include zcd-mixins.rotate-active;
      }
    }
  }
  .expandedSection {
    padding: 24px;
  }
}
.resultsQuestionsBreakdown:first-child {
  margin-top: -20px;
}
.resultsQuestionsBreakdown:last-child {
  margin-bottom: -20px;
}

.widgetWrapper {
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}

@use '~styles/colors';
@use '~styles/fontSizes';

.emptyStateWithCustomIcon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 133px);
  justify-content: center;

  > .content {
    padding: 32px 60px;
    text-align: center;

    > h2 {
      font-size: fontSizes.$font-large;
      margin: 0 0 8px;
    }

    > .description {
      color: colors.$info-color;
      font-size: fontSizes.$font-regular;
      max-width: 530px;
    }
    > .description + * {
      margin-top: var(--padding-standard);
    }
  }

  .icon {
    &.circular {
      border-radius: 100px;
    }
  }
}

@use '~styles/fontSizes';
@use '~styles/colors';

.fullScreenEmptyState {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px - (28px * 2));
  justify-content: center;
  padding: 28px;

  .content {
    padding: 32px 60px;
    text-align: center;

    > h2 {
      font-size: fontSizes.$font-large;
      margin: 0 0 8px;
    }

    > .description {
      color: colors.$info-color;
      font-size: fontSizes.$font-regular;
      max-width: 530px;
    }
    > .description + * {
      margin-top: var(--padding-standard);
    }
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

$demographic-groups-header-height: 64px;

.demographicGroupIemHeader {
  align-items: center;
  border-bottom: 1px solid zcd-colors.$zcd-gray-40;
  display: flex;
  height: $demographic-groups-header-height;
  padding: 12px 24px;

  .title {
    @include zcd-typography.t-heading-3;
  }

  .demographicGroupsButtonContainer {
    border: 1px solid zcd-colors.$zcd-gray-40;
    border-radius: 4px;
    > button:first-child {
      border-inline-end: 1px solid zcd-colors.$zcd-gray-40;
    }
  }

  .demographicGroupButton {
    @include zcd-typography.t-small;

    cursor: pointer;
    padding: 8px;
  }

  .activeDemographicGroupButton {
    background: zcd-colors.$zcd-blue-20;
  }
}

@use '~styles/colors';
@use '~styles/media';
@use '@zencity/common-ui/lib/zcd-typography';

.inner {
  height: 266px;
  overflow-y: auto;
}

@media only screen and (max-width: media.$screen-sm-width) {
  .leadingTopicsWidget {
    width: 100%;
  }
}

.title {
  @include zcd-typography.t-tag;

  color: colors.$color-dodger-blue-dark;
  margin: 0 24px;
}

.buttonContainer {
  margin: 16px 24px 0;
}

.emptyStateTitle {
  @include zcd-typography.t-heading-5;

  margin: 0 24px;
  padding: 16px 0;
}

$widget-box-width: 450px;

.widgetBox {
  display: flex !important;
  flex-direction: column;
  // Max height is the screen - the header height - margin top - margin bottom.
  max-height: calc(100vh - 133px - 24px - 96px);
  overflow: hidden;
  // Overwrite padding of common-ui card wrapper.
  padding: 20px 0 0 !important;
  width: $widget-box-width;
}

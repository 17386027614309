@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-mixins';

.tableWrapper {
  > div {
    border: 1px solid zcd-colors.$zcd-gray-40;
    border-radius: 8px;
    max-height: 450px;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    border-style: hidden;
    white-space: normal;

    thead {
      background-color: zcd-colors.$zcd-blue-20;
      border-bottom: 1px solid zcd-colors.$zcd-blue-20;
      position: sticky;
      top: 0;
      z-index: 1;
    }
    th {
      @include zcd-typography.t-tag;

      background-color: zcd-colors.$zcd-blue-20;
      border-bottom-width: 0;
      border-left: 2px solid zcd-colors.$zcd-gray-40;
      padding: 0;
      text-transform: unset;
      &:first-child {
        background-color: zcd-colors.$zcd-blue-20;
        left: 0;
        position: sticky;
        text-align: start;
      }
      > div {
        display: flex;
        width: 100%;
      }
    }

    tr td {
      border-left: 2px solid zcd-colors.$zcd-gray-40;
      padding: 0;

      &:first-child {
        background-color: #fff;
        left: 0;
        position: sticky;
      }
    }
  }

  .baseHeader {
    @include zcd-typography.t-heading-4;

    color: zcd-colors.$zcd-gray-100;
  }

  .cycleHeader {
    @include zcd-typography.t-mini;

    color: zcd-colors.$zcd-gray-70;
  }
}

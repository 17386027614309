.answerChoicesBreakdown {
  > .texts {
    --percentage-width: 50px;
    --num-of-respondents-width: 85px;

    margin-bottom: 4px;
    margin-top: 14px;

    &:first-child {
      margin-top: 0;
    }

    > span {
      display: inline-block;
    }
    > span:first-child {
      // choice's label
      width: calc(100% - var(--num-of-respondents-width) - var(--percentage-width));
    }
    > span:nth-child(2) {
      // number of respondents
      color: var(--info-color);
      text-align: right;
      width: var(--num-of-respondents-width);
    }
    > span:last-child {
      // percentage text
      font-weight: var(--bold-font-weight);
      text-align: right;
      width: var(--percentage-width);
    }
  }
}

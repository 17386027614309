@use '~styles/fontSizes';
@use '~styles/colors';
@use '~styles/media';

.header {
  font-size: fontSizes.$font-large;
  font-weight: 600;
  height: unset;
  padding: 20px 24px;
}
.areaBreakdown {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 332px;
  padding: unset !important;
  ol {
    // The list should scroll after 4 list items
    // (with three 1px horizontal splitters in between each list item).
    height: (media.$breakdown-row-height * 4) + 3;
    margin: unset;
    overflow-y: scroll;
    padding: 0 24px;
  }
  .map {
    border-left: 1px solid var(--light-gray);
    height: 100%;
    width: 100%;
  }
}

.loadingSpinner {
  display: flex;
  justify-content: center;
}

@use '~styles/media';

.summary {
  margin: 28px auto;
  max-width: media.$community-ask-widget-max-width;
  width: 100%;

  > div {
    // each question row
    padding-bottom: var(--padding-standard);
    padding-top: var(--padding-standard);
  }
}

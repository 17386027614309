$screen-xlarge-width: 1480px;
$screen-large-width: 1340px;
$screen-sl-width: 1200px; // small-large
$screen-medium-width: 1024px;
$screen-sm-width: 840px; // small-medium
$screen-small-width: 768px;
$mobile-max-width: 767px;
$mobile-large-width: 480px;
$mobile-small-width: 320px;
$community-ask-widget-max-width: 710px;
$community-ask-widget-width-small-screen: 996px;
$breakdown-row-height: 81px;
$header: 222px;
$results-progress-bar-width: 50%;
$results-info-section-width: 260px;
$summary-tab-overall-widget-width: 1215px;

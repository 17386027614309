@use '@zencity/common-ui/lib/zcd-typography';

.subheader {
  @include zcd-typography.t-heading-5;

  display: inline-block;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}

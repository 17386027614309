.topConcernsWidget {
  height: 100%;
  padding: 8px;

  .loaderMask {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }
}

@use '~styles/colors';
@use '~styles/fontSizes';
@use '~styles/fonts';
@use '@zencity/common-ui/lib/zcd-colors';

.answer {
  background-color: colors.$component-background;
  border-color: colors.$light-gray;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 16px;
  text-align: left;

  .topicList {
    .topic {
      background-color: zcd-colors.$zcd-blue-20;
      border-radius: 4px;
      font-size: fontSizes.$font-small;
      line-height: 18px;
      margin-right: 3px;
      margin-top: 12px;
      padding: 1px 4px;
      width: max-content;

      &.clickable {
        cursor: pointer;
        font-family: fonts.$font;
        &:hover {
          background-color: zcd-colors.$zcd-blue-40;
        }
      }
    }
  }

  .description {
    color: colors.$info-color;
    display: flex;
    flex-direction: column;
    font-size: fontSizes.$font-small;
    line-height: 18px;

    .date {
      margin-bottom: 4px;
    }

    .demographics {
      margin-bottom: 12px;
    }
  }

  .cxSurveyDataWrapper {
    color: #858181;
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;
    justify-content: space-between;
    margin-top: 12px;

    .contextLink {
      color: colors.$color-dodger-blue-dark;
    }
  }

  .content {
    font-size: fontSizes.$font-regular;
    line-height: 21px;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';
@use '~styles/zIndexes';

.mapWidget {
  border-right: 1px solid zcd-colors.$zcd-gray-40;
  grid-area: 1 / 1 / 2 / 2;
  height: 100%;
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: media.$screen-medium-width) {
    grid-area: 2 / 1 / 3 / 2;
  }

  .colorLegend {
    bottom: 0;
    margin-bottom: 24px;
    margin-left: 12px;
    position: absolute;
    z-index: calc(#{zIndexes.$map-base} + 2);
  }
}

@use '~styles/fontSizes';
@use '~styles/colors';

.backLink {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-size: fontSizes.$font-regular;
  line-height: 21px;
  text-decoration: none;

  &:hover {
    color: colors.$info-color;
  }
}

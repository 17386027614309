@use '~styles/colors';
@use '~styles/media';
@use '~styles/fontSizes';
@use '@zencity/common-ui/lib/zcd-vars';

/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
.screen {
  display: flex;
  justify-content: center;
  margin: 24px 28px 0;

  .summary {
    display: grid;
    max-width: media.$screen-large-width;

    :global(.screenLayoutLarge) & {
      width: media.$screen-large-width;
    }

    :global(.screenLayoutMedium) & {
      width: media.$screen-medium-width;
    }

    :global(.screenLayoutSmall) & {
      width: media.$screen-small-width;
    }

    :global(.screenLayoutMobileLarge) & {
      width: media.$mobile-large-width;
    }

    :global(.screenLayoutMobileSmall) & {
      width: media.$mobile-small-width;
    }

    .dashboardTopSection {
      display: flex;

      @media only screen and (max-width: zcd-vars.$zcd-breakpoint-md) {
        flex-direction: column;
      }
    }

    > section {
      column-gap: 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 24px;
      row-gap: 20px;

      > h2 {
        font-size: 20px;
        font-weight: 600;
        grid-column: 1/13;
        margin-bottom: 16px;
      }

      .respondentCount {
        font-size: 0.75rem;
        margin-top: 12px;
      }

      .datapointText {
        color: #6d6d6d;
        font-size: 1rem;
        margin-bottom: 8px;
      }

      .performanceRating {
        grid-column: 1/8;
        height: 365px;
      }

      .leaderboard {
        grid-column: 8/13;
        height: 365px;
      }

      .overallPerformanceContainer {
        column-gap: 12px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 12px;
      }

      .overallPerformanceSnapshot {
        grid-column: 1/6;
        height: 235px;
      }

      .overallPerformanceTimeseries {
        grid-column: 6/13;
        height: 205px;
        margin-top: 20px;
      }

      .rating {
        height: 265px;
      }

      .performanceRatingBox,
      .leaderboardBox,
      .ratingBox {
        height: inherit;
        overflow-y: scroll;

        > header {
          background-color: #fff;
          position: sticky;
          top: 0;
        }
      }

      .serviceTypesList {
        margin: 0;
        padding: 0;

        .isFilteredBy {
          color: #e2e2e2;
        }
      }

      .serviceTypeRow {
        align-items: center;
        display: flex;
        height: 2.5rem;
        justify-content: space-between;
        width: 100%;

        p {
          font-size: 14px;
        }

        > .noResponses {
          color: colors.$info-color;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }

      &.nextSteps {
        /* stylelint-disable-line */
        background-color: colors.$gray-background-color;
        border-radius: 8px;
        height: 283px;

        .takeAnAction {
          /* stylelint-disable-line */
          grid-column: 1/13;
        }
      }
    }
  }
}

.satisfactionDistribution {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
}

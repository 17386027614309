@use '~styles/colors';
@use '~styles/fonts';
@use '@zencity/common-ui/lib/zcd-colors';
/* stylelint-disable */
@import '@zencity/common-ui/lib/bundle.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
/* stylelint-enable */

body {
  background-color: zcd-colors.$zcd-gray-20;
  color: colors.$font-color;
  font-family: fonts.$font;
}

button {
  background-color: transparent;
  border: 0;
  color: colors.$font-color;
  padding: 0;
  &:focus {
    outline: none;
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';

.overallSatisfaction {
  grid-area: 1 / 1 / 2 / 2;
  height: 300px;

  @media only screen and (max-width: media.$screen-xlarge-width) {
    grid-area: 1 / 1 / 2 / 4;
  }

  > div {
    height: 100%;
  }
}

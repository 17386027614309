.organicDiscourseCard {
  > div {
    // .card-wrapper
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-between;
    padding: 24px;

    h2 {
      margin-bottom: 12px;
    }

    .cardTexts {
      margin-top: -5px;
    }

    > a {
      // .card-link
      margin-top: 20px;
    }

    > .centered {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
  }
}

@use '../../styles/fontSizes';
@use '../../styles/colors';

$border-radius: 8px;

.surveyRequestItem {
  background-color: colors.$component-background;
  display: flex;
  height: 146px;
  overflow: hidden;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid colors.$light-gray;
  }

  &.requested,
  &.drafted,
  &.canceled {
    cursor: not-allowed;
  }

  &.active:hover,
  &.closed:hover,
  &.completed:hover {
    background-color: colors.$highlight-background;
    cursor: pointer;
  }

  .iconContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 182px;

    &.active,
    &.closed,
    &.completed {
      background-color: #e8e9ff;
    }

    &.requested,
    &.canceled,
    &.drafted {
      background-color: colors.$gray-background-color;
    }
  }

  .surveyRequestContent {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: 20px 24px 24px;
    padding-left: 28px;
    padding-right: 24px;
    padding-top: 20px;

    > .requestTitle {
      margin-bottom: 8px;
    }

    > .results {
      align-self: flex-end;
    }
  }

  .statusDateContainer {
    display: flex;
    flex-grow: 2;
    font-size: fontSizes.$font-small;

    > .requestDate {
      color: colors.$info-color;
      font-weight: 400;
      margin-right: 12px;
    }
  }
}

@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';

.header {
  margin-bottom: 24px;
}
.title {
  @include zcd-typography.t-heading-2;

  margin-bottom: 2px;

  .label {
    color: zcd-colors.$zcd-gray-80;
  }
}

.summaryTitle {
  @include zcd-typography.t-body;
  .separator {
    color: zcd-colors.$zcd-gray-80;
    margin: 0 8px;
  }
  .resultsSummarySubmissions {
    color: zcd-colors.$zcd-gray-80;
  }
}

.crossQuestionTitle {
  color: zcd-colors.$zcd-blue-60;
}

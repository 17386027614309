@use '~styles/colors';
@use '~styles/fontSizes';

.container {
  display: flex;
  grid-column: 1/12;
  margin-bottom: 24px;
}

.label {
  color: colors.$info-color;
  font-size: fontSizes.$font-xlarge;
  font-weight: 600;
  line-height: 30px;
  margin-left: 5px;
}

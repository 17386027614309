@use '@zencity/common-ui/lib/zcd-colors';

.app {
  background-color: zcd-colors.$zcd-gray-20;
  display: flex;
  flex-direction: column;

  // surveys uses common-ui's smart icons, which define width&height on the <svg> itself,
  // so no need for css intervention, thus we reset here insight's css
  /* stylelint-disable selector-class-pattern */
  :global(.zc-icon) {
    height: auto;
    width: auto;
  }
}

.loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

@import '~styles/colors';

.takeAnAction {
  background-color: #ececec;
  border-radius: 8px;
  padding: 20px;
  width: 100%;

  .actionDeckTitle {
    margin-bottom: 20px;
  }

  > .cardsArea {
    column-gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.actionCard {
  flex-basis: 100%;
  height: 100%;
  height: 207px;
  text-decoration: none;

  .actionCardContent {
    background-color: $component-background;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;

    &:hover {
      background-color: $very-light-azure;
    }
    &:active {
      background-color: $light-azure;
    }

    p {
      color: $info-color;
      font-size: 14px;
      margin-top: 5px;
    }

    h3 {
      margin-top: 10px;
    }

    .linkText {
      align-items: center;
      color: $highlight;
      display: flex;
      font-size: 16px;
      margin-top: auto;

      i {
        margin-left: 8px;
      }
    }
  }
}

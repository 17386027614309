@use '@zencity/common-ui/lib/zcd-typography';
@use '@zencity/common-ui/lib/zcd-colors';

.cellWrapper {
  min-height: 48px;
  padding: 8px 16px;
}

.borderLeft {
  border-left: 2px solid zcd-colors.$zcd-gray-40;
}

.borderRight {
  border-right: 2px solid zcd-colors.$zcd-gray-40;
}

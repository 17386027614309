@use '@zencity/common-ui/lib/zcd-colors';
@use '@zencity/common-ui/lib/zcd-typography';
@use '~styles/media';
@use '~styles/zIndexes';

.geographicBreakdown {
  background-color: zcd-colors.$zcd-gray-10;
  border: 1px solid zcd-colors.$zcd-gray-40;
  border-radius: 8px;
  grid-area: 2 / 1 / 3 / 3;
  height: 440px;

  @media only screen and (max-width: media.$screen-xlarge-width) {
    grid-area: 3 / 1 / 4 / 4;
  }

  .header {
    @include zcd-typography.t-heading-5;

    align-items: center;
    border-bottom: 1px solid zcd-colors.$zcd-gray-40;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 24px;
    width: 100%;
  }

  .content {
    height: calc(100% - 64px);
    overflow: hidden;
  }

  .loaderMask {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;

    @media only screen and (max-width: media.$screen-medium-width) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
    }
  }
}

@use '~styles/colors';

.textarea {
  border: 1px solid colors.$light-gray;
  border-radius: 4px;
  box-sizing: border-box;
  font: inherit;
  height: 118px;
  margin-bottom: 40px;
  padding: 8px 16px;
  resize: none;
  width: 444px;
}

.subHeading {
  font-weight: 100;
  margin-bottom: 12px;
}

.icon {
  height: calc(100vh - 107px);
  width: auto;
}

.container {
  display: flex;
}

.form {
  background-color: colors.$component-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
  padding-right: 80px;
  width: 606px;
}

// stylelint-disable-next-line selector-no-qualifying-type
button.button {
  align-self: flex-start;
}
